import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import {
  MUTATION_DELETE_FILES_FROM_UPLOAD,
  MUTATION_REPROCESS_UPLOAD,
} from 'client/app/api/gql/mutations';
import { getDevicesUsedInSimulation } from 'client/app/apps/simulation-details/dataUtils';
import DownloadInstructionsButton from 'client/app/apps/simulation-details/DownloadInstructionsButton';
import ManualExecution from 'client/app/apps/simulation-details/overview/ManualExecution';
import ResourcesCard from 'client/app/apps/simulation-details/overview/ResourcesCard';
import DevicePanel from 'client/app/apps/simulation-details/overview/results/DevicePanel';
import DownloadCsvFiles from 'client/app/apps/simulation-details/overview/results/DownloadCsvFiles';
import { useDataUploadPolling } from 'client/app/apps/simulation-details/overview/results/hooks';
import LabwarePanel from 'client/app/apps/simulation-details/overview/results/LabwarePanel';
import { CreateMappedPlateButton } from 'client/app/apps/simulation-details/overview/results/PlateMapper';
import ResultDataPanel from 'client/app/apps/simulation-details/overview/results/ResultDataPanel';
import { ResultsCard } from 'client/app/apps/simulation-details/overview/results/ResultsComponents';
import {
  ElementOutputs,
  useFetchElementOutputs,
} from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import VisualizationLinks, {
  simHasVisualizations,
  useShowVisualizationLinks,
} from 'client/app/apps/simulation-details/overview/VisualizationLinks';
import { SimulationQuery } from 'client/app/gql';
import { DataFileState, DataUploadState } from 'client/app/gql';
import {
  hasDispenserDevice,
  hasManualDevice,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { basename } from 'common/lib/strings';
import { AnthaHubDeviceGUID } from 'common/types/device';
import { AnthaHubExecutionTask, ExecutionTaskStatus } from 'common/types/execution';
import { Deck } from 'common/types/mix';
import Button from 'common/ui/components/Button';
import ExecutionStatusBar from 'common/ui/components/ExecutionStatusBar';
import LinearProgress from 'common/ui/components/LinearProgress';
import { TaskRow } from 'common/ui/components/simulation-details/ExecutionTaskRow';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

export type SimulationWithExecution = SimulationQuery['simulation'] & {
  execution: NonNullable<SimulationQuery['simulation']['execution']>;
};

type Props = {
  simulation: SimulationWithExecution;
  deck: Deck;
  onRefresh: () => Promise<unknown>;
};

function ResultsScreen({ simulation, onRefresh, deck }: Props) {
  const classes = useStyles();
  const [elementOutputs, setElementOutputs] = useState<readonly ElementOutputs[] | null>(
    null,
  );

  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');

  const [isFileUploading, setIsFileUploading] = useState(false);

  const [isFileBeingDeleted, setIsFileBeingDeleted] = useState(false);

  const [plateFilesUploaded, setPlateFilesUploaded] = useState(false);

  const fetchElementOutputs = useFetchElementOutputs();
  useEffect(() => {
    const fetch = async () => {
      const elementOutputs = await fetchElementOutputs(simulation.filetreeLink);
      setElementOutputs(elementOutputs);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch();
  }, [fetchElementOutputs, simulation.filetreeLink]);

  const uploadsInProgress = simulation.execution.uploads.filter(
    u => u.state === DataUploadState.PENDING || u.state === DataUploadState.PROCESSING,
  );

  const errors = useMemo(
    () =>
      simulation.execution.uploads
        .filter(u => u.state === DataUploadState.COMPLETE)
        .map(u => ({
          id: u.id,
          filesWithErrors: u.dataFiles.items.filter(
            df => df.state !== DataFileState.COMPLETE,
          ),
        }))
        .filter(u => u.filesWithErrors.length > 0),
    [simulation.execution.uploads],
  );

  const [callsInProgress, setCallsInProgress] = useState(false);

  const [reprocessUpload] = useMutation(MUTATION_REPROCESS_UPLOAD);
  const [deleteFilesFromUpload] = useMutation(MUTATION_DELETE_FILES_FROM_UPLOAD);

  const retryErrors = useCallback(async () => {
    setCallsInProgress(true);
    await Promise.all(
      errors.map(u =>
        reprocessUpload({
          variables: { uploadId: u.id },
          // We expect this call will just update the state to PROCESSING, so return it
          // optimistically so the UI can immediately show the loading bar associated with
          // PROCESSING uploads.
          optimisticResponse: {
            __typename: 'Mutation',
            reprocessUpload: {
              __typename: 'DataUpload',
              id: u.id,
              state: DataUploadState.PROCESSING,
            },
          },
        }),
      ),
    ).finally(() => setCallsInProgress(false));
  }, [errors, reprocessUpload]);

  const deleteErroringFiles = useCallback(async () => {
    setCallsInProgress(true);
    await Promise.all(
      errors.map(u =>
        deleteFilesFromUpload({
          variables: {
            uploadId: u.id,
            fileIds: u.filesWithErrors.map(f => f.id),
          },
        }),
      ),
    ).finally(() => setCallsInProgress(false));
  }, [deleteFilesFromUpload, errors]);

  // Over-indexing an array produces undefined, so this produces the first item if there are any, or
  // undefined if the array is empty
  useDataUploadPolling(uploadsInProgress[0]?.id, () => {
    void onRefresh();
  });

  const anthaHubExecutionTasks = useMemo(
    () => convertExecutionTasksForAnthaHub(simulation),
    [simulation],
  );

  const hasVisualizations = useMemo(() => simHasVisualizations(simulation), [simulation]);

  const hasResultsToShow = Boolean(hasVisualizations || elementOutputs?.length);

  const showVisualizationLinks = useShowVisualizationLinks(simulation);
  const visLinksCard = useMemo(() => {
    return showVisualizationLinks ? (
      <ResultsCard header="Data Visualizations">
        <VisualizationLinks simulation={simulation} />
      </ResultsCard>
    ) : null;
  }, [showVisualizationLinks, simulation]);

  const isManual = hasManualDevice(simulation.workflow.workflow.Config);
  const isDispenser = hasDispenserDevice(simulation.workflow.workflow.Config);

  const hiddenContext = useContext(RouteHiddenContext);
  if (hiddenContext.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  return (
    <>
      {uploadsInProgress.length > 0 && (
        <div className={classes.panelSubheader}>
          Results are still being processed...
          <LinearProgress className={classes.linearProgress} />
        </div>
      )}
      {!callsInProgress && errors.length > 0 && (
        <div className={classes.errorContainer}>
          <Alert
            severity="error"
            variant="outlined"
            action={
              <>
                <Button
                  variant="secondary"
                  color="inherit"
                  className={classes.retryButton}
                  onClick={retryErrors}
                >
                  Retry
                </Button>
                <Button variant="secondary" color="inherit" onClick={deleteErroringFiles}>
                  Dismiss
                </Button>
              </>
            }
          >
            <AlertTitle>Data processing errors</AlertTitle>
            {errors.flatMap(u =>
              u.filesWithErrors.map(f => (
                <Typography key={f.originFiletreeLink}>
                  Error processing {basename(f.originFiletreeLink)}:{' '}
                  {truncateError(f.error) ||
                    (f.state === DataFileState.IGNORED
                      ? "This file couldn't be processed because it's the wrong type"
                      : 'An unknown error occurred during processing')}
                </Typography>
              )),
            )}
          </Alert>
        </div>
      )}
      <div className={classes.resultsScreen}>
        <div className={classes.resultsOverview}>
          <div className={classes.resultsColumns}>
            <ResourcesCard
              header="Labware"
              fullWidth
              headerAction={
                <CreateMappedPlateButton deck={deck} simulation={simulation} />
              }
            >
              <LabwarePanel
                simulation={simulation}
                deck={deck}
                onRefresh={onRefresh}
                setIsFileUploading={setIsFileUploading}
                setIsFileBeingDeleted={setIsFileBeingDeleted}
                setPlateFilesUploaded={setPlateFilesUploaded}
              />
            </ResourcesCard>
            <div className={classes.resultsColumn}>
              <ResourcesCard header="Results" fullWidth>
                {hasResultsToShow && (
                  <ResultDataPanel
                    simulationFiletreeLink={simulation.filetreeLink}
                    visLinksCard={visLinksCard}
                    elementOutputs={elementOutputs}
                  />
                )}
                <DownloadCsvFiles
                  simulation={simulation}
                  isFileUploading={isFileUploading}
                  isFileBeingDeleted={isFileBeingDeleted}
                  plateFilesUploaded={plateFilesUploaded}
                />
              </ResourcesCard>
              <ResourcesCard header="Execution Mode" fullWidth>
                {isManual ? (
                  <ManualExecution />
                ) : (
                  <DevicePanel
                    devicesConfiguration={getDevicesUsedInSimulation(simulation)}
                    simulation={simulation}
                  />
                )}
              </ResourcesCard>
            </div>
          </div>
          <div className={classes.executionStatusContainer}>
            <ResourcesCard
              header={isEnabledBreadcrumbs ? 'Execution details' : 'Execution Status'}
            >
              {isEnabledBreadcrumbs && (isDispenser || isManual) && (
                <ExecutionInstructionsContainer>
                  <ExecutionInstructionsTextContainer>
                    <Typography variant="h3">Execution instructions</Typography>
                    <Typography>
                      Download a pdf file of instructions to have a step by step execution
                      guides in the lab.
                    </Typography>
                  </ExecutionInstructionsTextContainer>
                  {isDispenser && !simulation.instructionsDownloaded && (
                    <ExecutionWarningContainer>
                      <Typography>
                        Please download instructions and manually upload to your dispenser
                        to start your execution in the lab
                      </Typography>
                    </ExecutionWarningContainer>
                  )}
                  <DownloadInstructionsButton
                    simulation={simulation}
                    manualExecution={isManual}
                  />
                </ExecutionInstructionsContainer>
              )}
              <div className={classes.executionStatusRowContainer}>
                {anthaHubExecutionTasks && (
                  <ExecutionStatusBar
                    tasks={anthaHubExecutionTasks}
                    hideHeader
                    fullWidth
                  />
                )}
                {anthaHubExecutionTasks
                  ?.filter(task => !task.isInternal)
                  .map(task => (
                    <TaskRow
                      key={task.id}
                      task={task}
                      fullWidth
                      // We don't need any of the below information in Results Screen.
                      // These are only used in AnthaHub. Defaulting to `false` is fine.
                      isAutorunToggleEnabled={false}
                      taskBelongsToAnotherAnthaHub={false}
                      shouldNextTaskRunAutomatically={false}
                      shouldThisTaskRunAutomatically={false}
                    />
                  ))}
              </div>
            </ResourcesCard>
          </div>
        </div>
      </div>
    </>
  );
}

function truncateError(s: string | null) {
  if (!s) {
    return s;
  }
  // 300 characters is a guess at the maximum length of a reasonable error message absent any parser
  // bugs or the like
  const MAX_LENGTH = 300;
  if (s.length > MAX_LENGTH) {
    return s.substr(0, MAX_LENGTH) + '...';
  }
  return s;
}

function convertExecutionTasksForAnthaHub(
  simulation: SimulationQuery['simulation'],
): AnthaHubExecutionTask[] | null {
  const { execution } = simulation;
  if (!execution) {
    console.error(
      `No Execution available for simulation "${simulation.name}" (ID: ${simulation.id})`,
    );
    return null;
  }
  const convertedTasks: AnthaHubExecutionTask[] = (execution.tasks ?? [])
    .filter(task => !!task?.simulationTask)
    .map(task => ({
      ...task,
      driverMessage: task.driverMessage || undefined,
      name: task.simulationTask!.name,
      isInternal: task.simulationTask!.isInternal,
      status: task.status as ExecutionTaskStatus,
      orderNum: task.simulationTask!.orderNum,
      lastModifiedAt: moment.utc(task.lastModifiedAt),
      // Not needed, as we don't gray out tasks belonging to different AnthaHubs
      anthaHubDeviceGuid: '' as AnthaHubDeviceGUID,
      // These are not needed as we don't show dependencies in this Tasks list.
      dependsOn: [],
      shouldRunAutomatically: false,
    }))
    .sort((taskA, taskB) => taskA.orderNum - taskB.orderNum);

  return convertedTasks;
}

const ExecutionInstructionsContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(3),
}));

const ExecutionInstructionsTextContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
}));

const ExecutionWarningContainer = styled('div')(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.primary.light,
  padding: spacing(3),
  borderRadius: spacing(2),
}));

const useStyles = makeStylesHook(theme => ({
  executionStatusContainer: {
    flex: 2,
    minWidth: '450px',
  },
  executionStatusRowContainer: {
    margin: theme.spacing(0, 3, 0),
  },
  resultsColumn: {
    flex: 1,
  },
  resultsOverview: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(3, 7, 7, 7),
  },
  resultsColumns: {
    display: 'flex',
  },
  resultsScreen: {
    display: 'flex',
    flex: 1,
    overflowY: 'scroll',
  },
  panelSubheader: {
    fontSize: '16px',
    width: '250px',
    margin: '16px auto 16px',
  },
  errorContainer: {
    width: '90%',
    marginTop: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  retryButton: {
    marginRight: '8px',
  },
  linearProgress: {
    marginTop: theme.spacing(5),
  },
}));

export default ResultsScreen;
