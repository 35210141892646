import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { getDevicesUsedInSimulation } from 'client/app/apps/simulation-details/dataUtils';
import ExecutionModeCard from 'client/app/apps/simulation-details/overview/ExecutionModeCard';
import ManualExecution from 'client/app/apps/simulation-details/overview/ManualExecution';
import ReagentsList from 'client/app/apps/simulation-details/overview/ReagentsList';
import ResourcesCard from 'client/app/apps/simulation-details/overview/ResourcesCard';
import ResourcesCardWithList from 'client/app/apps/simulation-details/overview/ResourcesCardWithList';
import { CardSection } from 'client/app/apps/simulation-details/overview/Types';
import DeviceSelector from 'client/app/components/Parameters/DeviceSelector';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { ArrayElement, SimulationQuery } from 'client/app/gql';
import { hasManualDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { getResinName } from 'common/lib/chromatography';
import { groupBy } from 'common/lib/data';
import { formatVolume, formatVolumeObj, pluralizeWord } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import { DeckItem, FilterMatrix, Plate } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { getHumanReadablePlateLabel } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import { getAllWellContentsOnPlate } from 'common/ui/components/simulation-details/mix/deckContents';
import getPlateInfo from 'common/ui/components/simulation-details/plate-prep/getPlateInfo';
import Styles from 'common/ui/components/simulation-details/Styles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckItems: readonly DeckItem[] | undefined;
  plateTypes: readonly PlateType[];
  simulation: SimulationQuery['simulation'];
};

export default function ResourcesOverview({ deckItems, plateTypes, simulation }: Props) {
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');
  const classes = useStyles();
  const formattedReagents: CardSection[] = useMemo(
    () =>
      simulation.reagents
        ? [
            {
              sectionHeader: '', // Only one section, no header needed
              // Take a copy of the reagents array because it's immutable
              sectionItems: [...simulation.reagents].sort(byName).map(mat => ({
                primaryLabel: mat.name,
                secondaryLabels: [formatVolume(mat.volumeUl, 'ul')],
                popoverContent:
                  mat.solutes.length > 0 || mat.tags.length > 0 ? (
                    <ReagentDetails reagent={mat} />
                  ) : undefined,
              })),
            },
          ]
        : [],
    [simulation.reagents],
  );

  const formattedLabware: CardSection[] = useMemo(() => {
    if (!deckItems || !simulation.tipUsage) {
      // Still fetching
      return [];
    }
    const plates = deckItems.filter((di): di is Plate => di.kind === 'plate');
    const tipwastes = deckItems.filter(di => di.kind === 'tipwaste');

    // Group robocolumns by resin and volume
    const roboColumnsCounts = plates
      .flatMap(plate => getAllWellContentsOnPlate(plate))
      .filter(
        (wellContents): wellContents is FilterMatrix =>
          wellContents.kind === 'filter_matrix_summary',
      )
      .reduce((counts, wellContents) => {
        const label =
          getResinName(wellContents) + ' ' + formatVolumeObj(wellContents.total_volume);
        counts.set(label, (counts.get(label) || 0) + 1);
        return counts;
      }, new Map<string, number>());

    const groupedPlateTypes = groupBy(plateTypes, 'type');
    function formatPlateToolTipContent(type: string) {
      const plateName = splitFullPlateName(type)?.[0];
      return groupedPlateTypes[plateName]
        ? getPlateInfo(groupedPlateTypes[plateName][0])
        : undefined;
    }

    function formatSection(groupedByType: { [type: string]: DeckItem[] }) {
      return Object.keys(groupedByType)
        .sort()
        .map(type => ({
          primaryLabel: type,
          // Just one label: the count of plates / tipwaste
          secondaryLabels: [groupedByType[type].length.toString()],
          tooltipContent:
            groupedByType[type][0].kind === 'plate'
              ? formatPlateToolTipContent(type)
              : undefined,
        }));
    }

    // Replace the backend concatenated type of the plate type with
    // a human-readable label
    function renamePlatesToHumanReadable(platesByType: { [type: string]: DeckItem[] }) {
      const withRenamedKeys = {} as { [type: string]: DeckItem[] };
      for (const [type, plateList] of Object.entries(platesByType)) {
        withRenamedKeys[getHumanReadablePlateLabel(type, plateTypes)] = plateList;
      }
      return withRenamedKeys;
    }

    return [
      {
        sectionHeader: 'Plates',
        sectionItems: formatSection(renamePlatesToHumanReadable(groupBy(plates, 'type'))),
      },
      {
        sectionHeader: 'Tips',
        sectionItems: simulation.tipUsage.map(usage => {
          const label = usage.tipType?.shortDescription ?? getLegacyLabel(usage.name);
          return {
            primaryLabel: label,
            secondaryLabels: [
              `${usage.tips.toString()} 
              (${usage.boxes}x ${pluralizeWord(usage.boxes, 'box', 'boxes')})`,
            ],
            tertiaryLabels: usage.tipType
              ? [formatVolumeObj(usage.tipType.maxVolume)]
              : undefined,
          };
        }),
      },
      {
        sectionHeader: 'Tipwaste',
        sectionItems: formatSection(groupBy(tipwastes, 'type')),
      },
      {
        sectionHeader: 'Chromatography Columns',
        sectionItems: [...roboColumnsCounts].map(([label, count]) => ({
          primaryLabel: label,
          secondaryLabels: [count.toString()],
        })),
      },
    ];
  }, [deckItems, plateTypes, simulation.tipUsage]);

  const devicesConfiguration = getDevicesUsedInSimulation(simulation);
  const isManualExecution = hasManualDevice(simulation.workflow.workflow.Config);

  return (
    <div className={classes.resourcesOverviewPanel}>
      <span className={classes.panelHeader}>Resources overview</span>
      <div className={classes.cardsBox}>
        <ResourcesCardWithList header="Labware" sections={formattedLabware} />
        {useFeatureToggle('REAGENTS_LIST') && simulation.reagents ? (
          <ReagentsList reagents={simulation.reagents} simulationName={simulation.name} />
        ) : (
          <ResourcesCardWithList header="Reagents" sections={formattedReagents} />
        )}
        {isEnabledBreadcrumbs ? (
          <ExecutionModeCard
            simulation={simulation}
            devicesConfiguration={devicesConfiguration}
          />
        ) : (
          <ResourcesCard header="Execution Mode">
            {isManualExecution ? (
              <ManualExecution />
            ) : (
              <DeviceSelector deviceConfiguration={devicesConfiguration} />
            )}
          </ResourcesCard>
        )}
      </div>
    </div>
  );
}

const MAX_VISIBLE_ITEMS = 4;

type ReagentDetailsProps = {
  reagent: ArrayElement<NonNullable<SimulationQuery['simulation']['reagents']>>;
};

function ReagentDetails({ reagent }: ReagentDetailsProps) {
  const classes = useStyles();
  return (
    <dl className={classes.popoverTable}>
      {reagent.solutes.length > 0 && (
        <>
          <dt className={classes.popoverSubheader}>Final Concentrations</dt>
          {reagent.solutes.slice(0, MAX_VISIBLE_ITEMS).map((solute, idx) => (
            <dt key={solute.name + idx}>
              <Typography variant="caption" color="textPrimary">
                {`${formatVolumeObj(solute.concentration)} ${solute.name}`}
              </Typography>
            </dt>
          ))}
          {reagent.solutes.length > MAX_VISIBLE_ITEMS && (
            <dt>
              <Typography variant="caption" color="textSecondary">
                +{reagent.solutes.length - MAX_VISIBLE_ITEMS} more...
              </Typography>
            </dt>
          )}
        </>
      )}
      {reagent.tags.length > 0 && (
        <>
          <dt
            className={cx(classes.popoverSubheader, {
              [classes.topPad]: reagent.solutes.length > 0,
            })}
          >
            Metadata
          </dt>
          {reagent.tags.slice(0, MAX_VISIBLE_ITEMS).map(tag => (
            <>
              <dt>
                <Typography variant="caption" color="textSecondary">
                  {tag.label || 'n/a'}
                </Typography>
              </dt>
              <dd>
                <Typography variant="caption" color="textPrimary">
                  {tag.valueFloat ?? (tag.valueString || 'n/a')}
                </Typography>
              </dd>
            </>
          ))}
          {reagent.tags.length > MAX_VISIBLE_ITEMS && (
            <dt>
              <Typography variant="caption" color="textSecondary">
                +{reagent.tags.length - MAX_VISIBLE_ITEMS} more...
              </Typography>
            </dt>
          )}
        </>
      )}
    </dl>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  resourcesOverviewPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '32px',
  },
  cardsBox: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    // Wrap cards on multiple lines if screen width is too small
    flexWrap: 'wrap',
  },
  panelHeader: Styles.panelHeader,
  popoverTable: {
    display: 'grid',
    gap: spacing(2, 2),
    margin: spacing(0),
    padding: spacing(2),
    minWidth: '200px',
    fontWeight: 'normal',
    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
    },
    color: palette.text.primary,
  },
  popoverHeader: {
    textAlign: 'center',
    padding: spacing(1),
  },
  popoverSubheader: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
  },
  topPad: {
    paddingTop: spacing(2),
  },
}));

/*
 * Prior to SYN-996 (Aug 2022), the TipUsage type didn't contain the actual
 * name of the tip (which we want to display to the user), it only had the name
 * of the tip box.
 * Fortunately, these old simulations only had a fixed set of tip types
 * available, so we keep a fixed mapping from tipbox name to tip name for
 * those boxes
 */
const tipNamesByBoxName = new Map([
  ['DL10 Tip Rack (PIPETMAX 8x20)', 'Gilson 20ul tips'],
  ['D200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul tips'],
  ['D200 Tip Rack (PIPETMAX 8x20)', 'Gilson 200ul tips for low volume'],
  ['DFL10 Tip Rack (PIPETMAX 8x20)', 'Gilson 10ul filter tips'],
  ['DF30 Tip Rack (PIPETMAX 8x20)', 'Gilson 30ul filter tips'],
  ['DF200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul filter tips'],
  ['DF200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul filter tips for low volume'],
  ['CyBio50Tipbox', 'CyBio 50ul tips'],
  ['CyBio250Tipbox', 'CyBio 250ul tips'],
  ['CyBio1000Tipbox', 'CyBio 1000ul tips'],
  ['DiTi 10uL LiHa', 'Tecan 10ul tips'],
  ['DiTi 50uL LiHa', 'Tecan 50ul tips'],
  ['DiTi 200uL LiHa', 'Tecan 200ul tips'],
  ['DiTi 1000uL LiHa', 'Tecan 1000ul tips'],
  ['DiTi Wide Bore 1000uL LiHa', 'Tecan 1000ul Wide Bore tips'],
  ['DiTi 10uL LiHa SBS', 'Tecan 10ul tips in SBS box'],
  ['DiTi 50uL LiHa SBS', 'Tecan 50ul tips in SBS box'],
  ['DiTi 200uL LiHa SBS', 'Tecan 200ul tips in SBS box'],
  ['DiTi 1000uL LiHa SBS', 'Tecan 1000ul tips in SBS box'],
  ['DiTi 200uL MCA96', 'Tecan 200ul tips for MCA96 in ANSI/SLAS format box'],
  ['DiTi 50uL MCA96', 'Tecan 50ul tips for MCA96 in ANSI/SLAS format box'],
  ['DiTi 350uL LiHa SBS', 'Tecan 350ul tips in ANSI/SLAS format box'],
  ['TecanFixed', 'Tecan Fixed Tips'],
  ['Hx50 Tipbox', 'Hamilton 50ul Tips'],
  ['Hx300 Tipbox', 'Hamilton 300ul Tips'],
  ['Hx1000 Tipbox', 'Hamilton 1000ul Tips'],
  ['Hx50F Tipbox', 'Hamilton 50ul Filtered Tips'],
  ['Hx300F Tipbox', 'Hamilton 300ul Filtered Tips'],
  ['Hx1000F Tipbox', 'Hamilton 1000ul Filtered Tips'],
]);

function getLegacyLabel(tipboxName: string): string {
  return tipNamesByBoxName.get(tipboxName) ?? 'unknown tip type';
}
