export enum FeatureToggleKind {
  INTERNAL = 'INTERNAL',
  UPCOMING = 'UPCOMING',
}

export enum FeatureToggleOverride {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NONE = 'NONE',
}

export type FeatureToggleConfig = {
  override: FeatureToggleOverride;
};

// This type is used in GraphQL schema - all changes should be backward-compatible.
export type FeatureToggle = {
  name: string;
  enabled: boolean;
  // Descriptive text about the feature, shown in the feature toggles dialog
  description: string;
  // Set to true if toggling the feature requires a page reload
  requiresReload: boolean;
  // Whether the feature only be used internally by Synthace employees or
  // if it's a feature in development not ready to be released yet.
  kind: FeatureToggleKind;
  // Configuration of the feature toggle used to calculate if the feature toggle is enabled or not.
  // This field shouldn't be used by the regular client and is provided for
  // administrative purposes.
  config?: FeatureToggleConfig;
};

export type FeatureDefinition = {
  defaultValue: 'on' | 'off';
  /**
   * Descriptive text about the feature, shown in the feature toggles dialog.
   */
  description: string;
  /**
   * Set to true if toggling the feature requires a page reload.  This is the
   * case when the feature toggle is referenced in e.g. global variables.
   *
   * This is not needed when the FeatureToggles API is used the recommended way,
   * through `FeatureTogglesContext` in React components and direct calls to
   * isEnabled in XHR/handler codepaths.
   */
  requiresReload?: boolean;

  /**
   * Whether this feature should be enabled on local development environment.
   * Defaults to false.
   */
  enableOnLocalhost?: boolean;
};

// From https://stackoverflow.com/questions/54598322/how-to-make-typescript-infer-the-keys-of-an-object-but-define-type-of-its-value
const inferFeatureNames = <T>(et: { [K in keyof T]: FeatureDefinition }) => et;

/**
 * These features should only be used internally by Synthace employees.
 */
export const internalFeatureList = inferFeatureNames({
  BULK_DELETE_DEVICE: {
    defaultValue: 'off',
    description:
      'Allow bulk deletion of devices in the the Device Library. Use carefully.',
  },
  DOE_ANALYSE_RESPONSES_SETTINGS: {
    defaultValue: 'off',
    description:
      'Shows the Settings panel in the DOE Analyse Responses Experiment Map method. Gives access to app settings not needed normally (e.g. a fallback from Python to R for linear regression).',
  },
  ELEMENT_CONFIGURATION_DEBUG_MODE: {
    defaultValue: 'off',
    description:
      'When element configuration is enabled, display original element and parameter names to aid debugging.',
  },
  SHOW_COLOR_LOGO: {
    defaultValue: 'off',
    description:
      'For demo purposes, show the logo with the color version instead of the default env color.',
  },
  SHOW_ELEMENT_RELEASE_QUALITY: {
    defaultValue: 'on',
    description:
      'Show what stage of development is for a particular element (e.g. Beta, Client Specific)',
  },
  SIMULATION_PLAYBACK: {
    defaultValue: 'off',
    description:
      'Adds a Play/Pause button to the simulation preview that automatically steps through a simulation at 120x speed.',
  },
  SYNTHACE_INTERNAL_WORKFLOW_DOWNLOAD: {
    defaultValue: 'on',
    description:
      'For people working on element code and Antha core. Enables downloading ' +
      'workflows in 2.0 format.',
  },
  SYNTHETIC_DATA: {
    defaultValue: 'off',
    description: 'Enable creating synthetic data for demo or testing purposes',
  },
  DISABLE_FOCUS_TRAP: {
    defaultValue: 'off',
    description:
      'Disabling the focus trap allows focus to escape menu/modals. Turn this ON if you need to type into a textfield (e.g. in Intercom) while a menu/modal is open.',
  },
  VISSERVER_BOKEH_3: {
    defaultValue: 'off',
    description: 'Use experimental `visserver` instance based on Bokeh 3 (SYN-5065)',
  },
  VIS_RBC_LAUNCH_DATA_PREP: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'Button to pivot samples table on RBC index and create a downstream Prepared Data node',
  },
  VIS_ACTIVE_LEARNING: {
    defaultValue: 'off',
    description: 'Enable functionality around active learning (R&D).',
  },
  VIS_GENERIC_DATA_PREPARATION: {
    defaultValue: 'off',
    description: 'Enable prototype generic data preparation app (R&D).',
  },
  HEAP_DATA_TRACKING: {
    defaultValue: 'off',
    description: 'Enable tracking using Heap',
  },
});

/**
 * These are features that we are in the developmental stage and aren't ready to be released, but
 * when released, this will be for all customers.
 */
export const upcomingFeatureList = inferFeatureNames({
  ACCESSIBLE_DEVICE_SETUP: {
    defaultValue: 'off',
    description: 'A dialog to edit the advanced setup for each accessible device',
  },
  BIOPROCESS_UI: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'Display of datasets in a Experiments Page tab and as Experiment blocks.',
  },
  BREADCRUMBS: {
    defaultValue: 'off',
    description:
      'Display platform navigation Breadcrumbs as the app header and enable all related features.',
  },
  DOE_DOUBLE_MAP_EDITOR: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'Enable the new double map editor for use with the Define Mix Set Plan element in the DOE Template Editor',
  },
  HAMILTON_STANDARD_LANGUAGE: {
    defaultValue: 'off',
    description:
      'Compile HSL for Hamilton Venus devices. Requires Hamilton plugin 0.20.0.5798 or greater in AnthaHub.',
  },
  HIGH_LEVEL_INSTRUCTIONS: {
    defaultValue: 'off',
    description: 'Show high-level instructions on the simulation details page',
  },
  BARRIER_MERGING: {
    defaultValue: 'off',
    description: 'Revert behaviour of planner to merging barriers together',
  },
  PLATE_MAPPER: {
    defaultValue: 'off',
    description:
      'Allow defining manually cherry-picked and pooled plates in the results screen',
  },
  TYPE_CONFIGURATION_EDITOR_SETTINGS: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'Use type configuration values for editor selection (controlled on the server-side)',
  },
  TYPE_CONFIGURATION_CONNECTION_SETTINGS: {
    defaultValue: 'off',
    description:
      'Use type configuration values for determining whether two ports of different types can connect in the UI.',
  },
  VIS_RBC_COMPUTED_COLUMNS_PROTOTYPE: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'Enable computed columns to be added interactively to the RoboColumns analysis',
  },
  SHOW_SIMULATIONS_BADGE: {
    defaultValue: 'off',
    description: 'Show the simulations badge for unread simulations for builder.',
  },
  SHOW_ERROR_PREVIEW: {
    defaultValue: 'off',
    description: 'Attempt to show the mix-preview after a failed simulation.',
  },
  SYNTHACE_BIO_REDIRECT: {
    defaultValue: 'on',
    description: 'Automatically redirect to *.synthace.bio domain',
  },
  LOAD_ROBOCOLUMN_BY_MASS: {
    defaultValue: 'off',
    description:
      'Allow specifying amount of liquid to load into columns as a unit of mass',
  },
  DATASET_TIMEZONES: {
    defaultValue: 'off',
    description:
      'Allow specifying timezone for samples and events entered in the dataset settings',
  },
  NEXT_GEN_BIOPROCESS_BLOCK: {
    defaultValue: 'off',
    description: 'Enable next-gen UI for the bioprocess block',
  },
  DOE_ALPHA: {
    defaultValue: 'off',
    enableOnLocalhost: true,
    description:
      'All DOE Alpha related features: pressing Design in the DOE Template Editor will launch design mode instead of upload mode, enables the launch response analsyis button on the DOE tab, enables button to launch visserver workspace app for navigation.',
  },
  NEW_DOE: {
    defaultValue: 'off',
    description:
      'Enable the new, integrated DOE capability within the platform, including new Workflow Builder design and ability to toggle between "Build" and "DOE" modes, and integrated DOE functionality within the Experiment Map',
  },
  DOE_CALCULATED_OPTIMAL_DESIGNS: {
    defaultValue: 'off',
    description:
      'Enable to allow the doe_designer visserver app to calculate Optimal DOE designs on-the-fly',
  },
  PARAMETER_VALIDATION: {
    defaultValue: 'off',
    description:
      'Validate element parameters by running workflow elements in the background.',
  },
  MANUAL_MULTICHANNEL: {
    defaultValue: 'off',
    description: 'Enable to allow modelling of multichannel heads on a manual device',
  },
  EXPERIMENT_MAP_BIOPROCESS: {
    defaultValue: 'off',
    description: 'Bioprocess protocol within Experiment Map',
  },
  EXPERIMENT_MAP_LIQUID_GRAPH: {
    defaultValue: 'off',
    description: 'Liquid Graph within Experiment Map',
  },
  EXPERIMENT_MAP_ROBOCOLUMNS: {
    defaultValue: 'off',
    description: 'Robocolumns protocol within Experiment Map',
  },
  EXPERIMENT_MAP_WORKBOOK: {
    defaultValue: 'off',
    description: 'Enable creation of Excel Workbook Block in Experiment Map',
  },
  EXPERIMENT_MAP_NOTEBOOK: {
    defaultValue: 'off',
    description: 'Enable creation of Jupyter Notebook Block in Experiment Map',
  },
  REAGENTS_LIST: {
    defaultValue: 'off',
    description: 'Enable the new Reagents list in the simulation overview',
  },
});
