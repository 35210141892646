import React, { useMemo, useRef } from 'react';

import { styled } from '@mui/material/styles';

import Instructions, {
  PrintableInstructions,
} from 'client/app/apps/simulation-details/instructions/Instructions';
import { TableOfContents } from 'client/app/apps/simulation-details/instructions/TableOfContents';
import {
  GroupMeta,
  GroupsByStep,
} from 'client/app/apps/simulation-details/instructions/types';
import { Simulation } from 'client/app/gql';
import {
  ElementStepGroup,
  GroupStepGroup,
  RepeatStepGroup,
  StepsGroup,
  StepsJson,
} from 'common/types/steps';
import Print from 'common/ui/components/Print';
import { TrackActiveProvider } from 'common/ui/components/TrackActive';

type Props = {
  simulation: Simulation;
  instructions: StepsJson;
};

const INSTRUCTIONS_ID = 'highLevelInstructions';

export default function InstructionsScreen({ simulation, instructions }: Props) {
  const groups = useMemo(() => {
    const result: GroupsByStep = {};

    if (!instructions.groups) {
      return result;
    }

    const groups: (GroupMeta & StepsGroup)[] = instructions.groups.map((group, id) => ({
      ...group,
      id,
      memberIds: [],
    }));

    instructions.steps.forEach(step => {
      step.groups.forEach(id => {
        const group = groups[id];
        group.memberIds.push(step.id);

        if ('groupID' in group.meta) {
          group;
          result[step.id] = {
            ...result[step.id],
            group: group as GroupMeta & GroupStepGroup,
          };
        } else if ('elementID' in group.meta) {
          result[step.id] = {
            ...result[step.id],
            element: group as GroupMeta & ElementStepGroup,
          };
        } else if ('repeats' in group.meta) {
          result[step.id] = {
            ...result[step.id],
            repeat: group as GroupMeta & RepeatStepGroup,
          };
        }
      });
    });

    return result;
  }, [instructions]);

  const printableContentRef = useRef<HTMLDivElement | null>(null);

  return (
    <TrackActiveProvider rootId={INSTRUCTIONS_ID}>
      <Wrapper>
        <TableOfContents steps={instructions.steps} groups={groups} />
        <Instructions steps={instructions.steps} groups={groups} id={INSTRUCTIONS_ID} />
        <PrintableInstructions
          simulation={simulation}
          steps={instructions.steps}
          groups={groups}
          ref={printableContentRef}
        />
        <Print
          content={() => printableContentRef.current}
          onBeforeGetContent={() => {
            if (printableContentRef.current) {
              printableContentRef.current.style.visibility = 'visible';
            }
          }}
          onAfterPrint={() => {
            if (printableContentRef.current) {
              printableContentRef.current.style.visibility = 'hidden';
            }
          }}
        />
      </Wrapper>
    </TrackActiveProvider>
  );
}

const Wrapper = styled('div')({
  overflow: 'hidden auto',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr 0',
  gridTemplateRows: 'auto',
  placeItems: 'stretch',
});
