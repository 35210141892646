import React, { useContext } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

import { useNodeState } from 'client/app/apps/work-tree/useNodeState';
import { NODE_HEIGHT, NODE_WIDTH } from 'client/app/apps/work-tree/workTreeDimensions';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { FavoritedBy, FavoriteStarIconButton } from 'client/app/components/FavoriteStar';
import { EXPERIMENT_SOURCE_PARAM } from 'client/app/components/nav/experiment-chip-picker/ExperimentChipPicker';
import {
  ArrayElement,
  ExperimentWorkTreeQuery,
  MethodStatus,
  WorkTreeApplicationName,
} from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import stopPropagation from 'common/lib/stopPropagation';
import Colors, { PERCEPTUAL_PALETTE } from 'common/ui/Colors';
import Popover, { PopoverSection } from 'common/ui/components/Popover';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { GraphDataIcon } from 'common/ui/icons/GraphDataIcon';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { SnapshotIcon } from 'common/ui/icons/SnapshotIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

export type EntityNodeProps = Block & {
  experimentid: ExperimentId;
};

export default function EntityNode(props: NodeProps<EntityNodeProps>) {
  const classes = useStyles();
  const { selected, greyedOut, clickable, highlighted } = useNodeState(props.data);

  return (
    <Paper
      className={cx(classes.node, {
        [classes.selectedNode]: selected,
        [classes.greyedOutNode]: greyedOut,
        [classes.clickableNode]: clickable,
        [classes.highlightedNode]: highlighted,
      })}
    >
      <EntityNodeContent {...props} />
    </Paper>
  );
}

function EntityNodeContent({ data: block }: NodeProps<EntityNodeProps>) {
  switch (block.applicationName) {
    case WorkTreeApplicationName.cherry_picker:
    case WorkTreeApplicationName.doe_template_editor:
    case WorkTreeApplicationName.workflow_builder:
    case WorkTreeApplicationName.snapshot:
      return <EntityNodeDetails block={block} />;
    case WorkTreeApplicationName.simulate:
    case WorkTreeApplicationName.execute:
      return block.applicationVersion === '0.0.0' ? (
        <EntityNodeDetails block={block} showFavorited showStatus />
      ) : (
        <EntityNodeDetails block={block} isDataSetApplication showStatus />
      );
    case WorkTreeApplicationName.align:
    case WorkTreeApplicationName.analyse:
    case WorkTreeApplicationName.append:
    case WorkTreeApplicationName.bioprocess:
    case WorkTreeApplicationName.bp_bioprocess:
    case WorkTreeApplicationName.design:
    case WorkTreeApplicationName.lg_export:
    case WorkTreeApplicationName.lg_link_plates:
    case WorkTreeApplicationName.lg_load_bioprocess:
    case WorkTreeApplicationName.lg_load_execution:
    case WorkTreeApplicationName.rbc_analyse:
    case WorkTreeApplicationName.rbc_cherry_pick:
    case WorkTreeApplicationName.rbc_view:
    case WorkTreeApplicationName.notebook:
    case WorkTreeApplicationName.render:
    case WorkTreeApplicationName.view:
    case WorkTreeApplicationName.workbook:
      return <EntityNodeDetails block={block} isDataSetApplication showStatus />;
    case WorkTreeApplicationName.wf_builder:
    case WorkTreeApplicationName.wf_cherry_picker:
    case WorkTreeApplicationName.wf_form_editor:
    case WorkTreeApplicationName.doe_analyse_responses:
    case WorkTreeApplicationName.doe_prepare_data:
      return <EntityNodeDetails block={block} isDataSetApplication showStatus={false} />;
    default:
      return <EntityNodeDetails block={block} showStatus />;
  }
}

function EntityNodeDetails({
  block,
  showStatus,
  isDataSetApplication,
  showFavorited,
}: {
  block: EntityNodeProps;
  isDataSetApplication?: boolean;
  showStatus?: boolean;
  showFavorited?: boolean; // Only required for simulation or execution nodes currently
}) {
  const classes = useStyles();

  const context = useContext(WorkTreeModeContext);
  const { selectable, highlighted } = useNodeState(block);
  const hasVersionData = block.data?.versionData;

  const { icon, color, copy } = getEntityNodeIconAndCopy(block.applicationName);

  const handleClick = () => {
    if (selectable && !hasVersionData) {
      context.addSelectedBlock(block.id);
    } else if (selectable && hasVersionData) {
      const versionData = [...block.data!.versionData];
      versionData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      if (versionData.length > 0) {
        context.addSelectedBlock(versionData[0].id);
      }
    } else if (context.mode === 'editing-hidden-blocks') {
      context.toggleHidden(block.id);
    }
  };

  const selectedIndex = Math.min(context.selectedBlocks.indexOf(block.id) + 1, 99);
  let maxNumberOfSelectedBlocks = Infinity;
  if (
    context.mode === 'creating-block' &&
    context.creatingBlock.inputResultRequirements.maxItems
  ) {
    maxNumberOfSelectedBlocks = context.creatingBlock.inputResultRequirements.maxItems;
  }

  const fmtCreatedAt = block.createdAt ? formatDateTime(new Date(block.createdAt)) : '';

  const shouldShowMultiLineText = !showStatus;

  let resultName = isDataSetApplication ? block.label : block.result?.name;
  if (!resultName) {
    // Fallback case for any non-defined names
    resultName = copy.toUpperCase();
  }

  const updatedHref = addExperimentIdParams(block.href, block.experimentid);

  const title = (
    <Typography
      className={cx(classes.nodeName, {
        [classes.nodeNameSingleLine]: !shouldShowMultiLineText,
        [classes.nodeNameMultiLine]: shouldShowMultiLineText,
      })}
      variant="body2"
    >
      {resultName}
    </Typography>
  );

  const showVersions = block.data?.versionData && block.data.versionData.length > 0;

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        className={classes.handle}
      />
      <>
        {highlighted && block.applicationName === WorkTreeApplicationName.execute && (
          <Typography
            className={classes.highlightedTextHelp}
            color="textSecondary"
            variant="caption"
          >
            Click ‘+’ button in the toolbar to continue
          </Typography>
        )}
        <div className={classes.nodeContents} onClick={handleClick}>
          <div
            className={cx(classes.nodeIcon, {
              [classes.nodeIconWithVersions]: showVersions,
            })}
            style={{ backgroundColor: color }}
          >
            {icon}
          </div>
          <div className={classes.nodeBody}>
            <Popover
              title={
                <div>
                  {block.applicationDisplayName && (
                    <PopoverSection header="Type" text={block.applicationDisplayName} />
                  )}
                  {resultName && <PopoverSection header="Name" text={resultName} />}
                  <PopoverSection
                    header="Created by"
                    text={block.createdBy.displayName}
                  />
                  {fmtCreatedAt && (
                    <PopoverSection header="Created at" text={fmtCreatedAt} />
                  )}
                </div>
              }
            >
              {context.mode === 'default' ? (
                <Link
                  color="textPrimary"
                  onClick={stopPropagation}
                  href={updatedHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                >
                  {title}
                </Link>
              ) : (
                title
              )}
            </Popover>
            {showStatus && (
              <Typography
                variant="caption"
                style={{ color: getStatusLabelColor(block.status) }}
              >
                {block.status}
              </Typography>
            )}
          </div>
          <div className={classes.nodeIcons}>
            {showFavorited && block.isFavoritedByCurrentUser && (
              <FavoriteStarIconButton
                className={classes.favoriteStar}
                status={FavoritedBy.FAVORITED_BY_SELF}
                size="xsmall"
                disabled
              />
            )}
            {context.mode === 'editing-hidden-blocks' && (
              <VisibilityButton block={block} />
            )}
            {context.mode === 'creating-block' &&
              !hasVersionData &&
              selectable &&
              selectedIndex > 0 && (
                <MultiSelectIcon
                  className={classes.entitiNodeMultiSelectIcon}
                  index={selectedIndex}
                  warningState={selectedIndex > maxNumberOfSelectedBlocks}
                />
              )}
          </div>
        </div>
        {showVersions && <NodeVersions {...block} />}
      </>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        className={classes.handle}
      />
    </>
  );
}

function VisibilityButton({ block }: { block: Block }) {
  const classes = useStyles();

  const { greyedOut, clickable } = useNodeState(block);

  if (greyedOut) {
    return (
      <Tooltip
        title={
          clickable
            ? ''
            : 'This block cannot be unhidden because a parent block is hidden'
        }
      >
        <VisibilityOff
          className={cx(classes.hideIcon, { [classes.hideIconDisabled]: !clickable })}
        />
      </Tooltip>
    );
  } else {
    return (
      <Visibility
        className={cx(classes.hideIcon, { [classes.hideIconDisabled]: !clickable })}
      />
    );
  }
}

export function NodeVersions(block: EntityNodeProps) {
  const classes = useStyles();
  const context = useContext(WorkTreeModeContext);
  const { selectable: parentBlockSelectable, greyedOut: parentBlockGreyedOut } =
    useNodeState(block);
  let maxNumberOfSelectedBlocks = Infinity;
  if (
    context.mode === 'creating-block' &&
    context.creatingBlock.inputResultRequirements.maxItems
  ) {
    maxNumberOfSelectedBlocks = context.creatingBlock.inputResultRequirements.maxItems;
  }
  const isCollapsed = !context.expandedBlocks.includes(block.id);

  const toggleCollapse = () => {
    context.toggleExpandBlock(block.id);
  };

  const handleHoverVersion = (versionId: BlockId | undefined) => {
    if (context.mode !== 'creating-block') {
      const versionBlock = block.data?.versionData.find(
        version => version.id === versionId,
      );
      const dependants = versionBlock ? versionBlock.dependants : [];
      context.onHoverVersion({
        parentBlockId: block.id,
        versionId,
        dependants,
      });
    }
  };

  const handleClickVersion = (versionId: BlockId) => {
    const versionBlock = block.data?.versionData.find(
      version => version.id === versionId,
    );
    const dependants = versionBlock ? versionBlock.dependants : [];
    context.onClickVersion({ parentBlockId: block.id, versionId, dependants });
  };

  if (!block.data) {
    return null;
  }

  const { color } = getEntityNodeIconAndCopy(block.applicationName);

  const versionData = [...block.data.versionData];
  versionData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  const latestVersion = versionData.length > 0 ? versionData[0] : 0;

  const latestVersionSelectedIndex = latestVersion
    ? Math.min(context.selectedBlocks.indexOf(latestVersion.id) + 1, 99)
    : 0;

  const handleClickVersionContainer = () => {
    if (latestVersion && parentBlockSelectable) {
      context.addSelectedBlock(latestVersion.id);
    }
  };

  return (
    <div className={classes.nodeVersionsContainer} onClick={handleClickVersionContainer}>
      <div
        className={cx(classes.nodeIcon, classes.nodeVersionsIcon)}
        style={{ backgroundColor: color }}
      />
      <div className={classes.nodeVersionsCollapseContainer}>
        <div
          className={cx(classes.nodeVersionsCollapseTitle, {
            [classes.nodeVersionsCollapseGreyedOut]: parentBlockGreyedOut,
          })}
        >
          {isCollapsed ? (
            <Typography className={classes.nodeVersionTimeStamp} variant="button">
              {formatDateTime(new Date(block.createdAt))}
            </Typography>
          ) : (
            <Typography>Past versions</Typography>
          )}
          {context.mode === 'creating-block' && parentBlockSelectable ? (
            <MultiSelectIcon
              className={classes.versionMultiSelectIcon}
              index={latestVersionSelectedIndex}
              warningState={latestVersionSelectedIndex > maxNumberOfSelectedBlocks}
            />
          ) : (
            <IconButton
              className={classes.collapseButton}
              onClick={toggleCollapse}
              size="small"
              disabled={
                versionData.some(version =>
                  context.selectedBlocks.includes(version.id),
                ) ||
                parentBlockGreyedOut ||
                context.mode === 'creating-block'
              }
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </div>
        <Collapse in={!isCollapsed}>
          <List className={classes.nodeVersionsList}>
            {versionData.map(versionBlock => {
              const updatedHref = addExperimentIdParams(
                versionBlock.href,
                block.experimentid,
              );

              const versionTitle = (
                <div>
                  <Typography
                    className={cx(classes.nodeName, classes.nodeNameSingleLine)}
                    variant="body2"
                  >
                    {versionBlock.label}
                  </Typography>
                  <Typography className={classes.nodeVersionTimeStamp} variant="button">
                    {formatDateTime(new Date(versionBlock.createdAt))}
                  </Typography>
                </div>
              );

              return (
                <ListItem
                  component="div"
                  key={versionBlock.id}
                  className={cx(classes.nodeVersionListItem, {
                    [classes.nodeVersionListItemHover]:
                      context.selectedVersion?.versionId === versionBlock.id,
                    [classes.nodeVersionListItemSelected]:
                      context.selectedVersion?.versionId === versionBlock.id &&
                      context.selectedVersion.userSelected,
                  })}
                  onMouseEnter={() => handleHoverVersion(versionBlock.id)}
                  onMouseLeave={() => handleHoverVersion(undefined)}
                  onClick={() => handleClickVersion(versionBlock.id)}
                >
                  {context.mode !== 'creating-block' ? (
                    <Link
                      color="textPrimary"
                      onClick={stopPropagation}
                      href={updatedHref}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.nodeVersionLink}
                      underline="hover"
                    >
                      {versionTitle}
                    </Link>
                  ) : (
                    versionTitle
                  )}
                  {versionBlock.dependants.length > 0 && (
                    <LinkOutlinedIcon className={classes.linkIcon} fontSize="small" />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </div>
    </div>
  );
}

type MultiSelectIconProps = {
  index: number;
  warningState?: boolean;
  className?: string;
};

function MultiSelectIcon(props: MultiSelectIconProps) {
  const { index, warningState, className } = props;
  const classes = useStyles();
  if (index === 0) {
    return null;
  }
  return (
    <Typography
      className={cx(
        classes.multiSelectIcon,
        {
          [classes.warningText]: warningState,
        },
        className,
      )}
    >
      {index}
    </Typography>
  );
}

// Adds the experimentid as a query parameter to the given href.
// Our hrefs come from the server as relative urls that can already contain parameters:
//
// e.g. /#/workflow/0f8a5563-ec08-4462-b65b-bfe81e92a950
// e.g. /#/vis/view/align?method_id=ae11b3f0-e121-4abb-a3db-d608eb861a9a
//
// This function returns an updated relative url.
function addExperimentIdParams(href: string, experimentid: ExperimentId): string {
  const hasParams = href.includes('?');
  const existingParams = hasParams ? href.split('?')[1] : '';
  const urlWithoutParams = hasParams ? href.split('?')[0] : href;
  const newParams = new URLSearchParams(existingParams);
  newParams.set(EXPERIMENT_SOURCE_PARAM, experimentid);
  return urlWithoutParams + '?' + newParams.toString();
}

function getStatusLabelColor(status: MethodStatus): string {
  switch (status) {
    case MethodStatus.COMPLETED:
      return Colors.SUCCESS_LIGHT;
    case MethodStatus.FAILED:
      return Colors.ERROR_MAIN;
    default:
      return Colors.INFO_MAIN;
  }
}

export function getEntityNodeIconAndCopy(applicationName: WorkTreeApplicationName): {
  icon: JSX.Element;
  copy: string;
  color: string;
} {
  let icon;
  let copy;
  let color;
  switch (applicationName) {
    case WorkTreeApplicationName.workflow_builder:
    case WorkTreeApplicationName.wf_builder:
    case WorkTreeApplicationName.wf_form_editor:
      icon = <WorkflowIcon fontSize="small" />;
      copy = 'WORKFLOW';
      color = PERCEPTUAL_PALETTE.blue;
      break;
    case WorkTreeApplicationName.snapshot:
      icon = <SnapshotIcon fontSize="small" />;
      copy = 'SNAPSHOT';
      color = PERCEPTUAL_PALETTE.blue;
      break;
    case WorkTreeApplicationName.cherry_picker:
    case WorkTreeApplicationName.wf_cherry_picker:
      icon = <CherryPickerIcon fontSize="small" />;
      copy = 'CHERRY PICKER';
      color = PERCEPTUAL_PALETTE.peach;
      break;
    case WorkTreeApplicationName.doe_template_editor:
      icon = <DOETemplateIcon fontSize="small" />;
      copy = 'DOE TEMPLATE';
      color = PERCEPTUAL_PALETTE.lightGreen;
      break;
    case WorkTreeApplicationName.simulate:
      icon = <SimulationIcon />;
      copy = 'SIMULATION';
      color = PERCEPTUAL_PALETTE.lavender;
      break;
    case WorkTreeApplicationName.execute:
      icon = <ExecutionIcon fontSize="small" />;
      copy = 'EXECUTION';
      color = PERCEPTUAL_PALETTE.yellow;
      break;
    case WorkTreeApplicationName.align:
    case WorkTreeApplicationName.analyse:
    case WorkTreeApplicationName.append:
    case WorkTreeApplicationName.design:
    case WorkTreeApplicationName.render:
    case WorkTreeApplicationName.view:
    case WorkTreeApplicationName.bioprocess:
    case WorkTreeApplicationName.notebook:
    case WorkTreeApplicationName.workbook:
      icon = <DataSetIcon fontSize="small" />;
      copy = applicationName;
      color = PERCEPTUAL_PALETTE.green;
      break;
    case WorkTreeApplicationName.data_parser:
      icon = <DataSetIcon fontSize="small" />;
      copy = applicationName;
      color = PERCEPTUAL_PALETTE.lavender;
      break;
    case WorkTreeApplicationName.doe_prepare_data:
      icon = <DataSetIcon fontSize="small" />;
      copy = 'DOE PREPARE DATA';
      color = PERCEPTUAL_PALETTE.green;
      break;
    case WorkTreeApplicationName.doe_analyse_responses:
      icon = <GraphDataIcon fontSize="small" />;
      copy = 'DOE ANALYSE RESPONSES';
      color = PERCEPTUAL_PALETTE.tan;
      break;
    default:
      icon = <WarningOutlined fontSize="small" />;
      copy = applicationName;
      color = Colors.GREY_50;
      break;
  }
  return { icon, copy, color: alpha(color, 0.4) };
}

const useStyles = makeStylesHook(theme => ({
  node: {
    boxShadow: `${theme.shadows[6]}, inset 0 0 0 1px ${Colors.GREY_30}`,
    borderRadius: '8px',
    backgroundColor: Colors.GREY_0,
    cursor: 'auto',
    pointerEvents: 'all',
    width: `${NODE_WIDTH}px`,
  },
  nodeIcon: {
    minWidth: '30px',
    minHeight: '100%',
    display: 'flex',
    borderRadius: '8px 0 0 8px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  nodeIconWithVersions: {
    borderBottomLeftRadius: 0,
  },
  nodeVersionsContainer: {
    display: 'flex',
    borderTop: `1px solid ${Colors.GREY_30}`,
  },
  nodeVersionsIcon: {
    borderTopLeftRadius: 0,
  },
  nodeVersionsCollapseContainer: {
    width: '100%',
  },
  nodeVersionsCollapseTitle: {
    height: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  nodeVersionsCollapseGreyedOut: {
    opacity: 0.5,
  },
  nodeVersionTimeStamp: {
    color: theme.palette.text.secondary,
  },
  nodeVersionsList: {
    padding: theme.spacing(0, 0, 4, 0),
  },
  nodeVersionListItem: {
    display: 'grid',
    gridTemplateColumns: '[title] 5fr [link-icon] 1fr',
    padding: theme.spacing(1, 3),
  },
  nodeVersionLink: {
    gridColumn: 'title',
  },
  nodeVersionListItemHover: {
    backgroundColor: Colors.BLUE_5,
  },
  nodeVersionListItemSelected: {
    backgroundColor: Colors.BLUE_20,
  },
  highlightedTextHelp: {
    position: 'absolute',
    top: '-24px',
    left: '10px',
  },
  linkIcon: {
    gridColumn: 'link-icon',
    justifySelf: 'center',
  },
  favoriteStar: {
    gridRow: 'upper-node-icon',
  },
  hideIcon: {
    gridRow: 'lower-node-icon',
  },
  hideIconDisabled: {
    color: Colors.ACTION_DISABLED,
  },
  nodeTitle: {
    fontSize: '12px',
  },
  selectedNode: {
    outline: `2px solid ${Colors.PRIMARY_DARK}`,
  },
  greyedOutNode: {
    backgroundColor: alpha(Colors.GREY_0, 0.6),
    boxShadow: `inset 0 0 0 1px ${alpha(Colors.GREY_30, 0.6)}`,
    '& $nodeIcon, & $nodeBody': {
      opacity: 0.5,
    },
  },
  clickableNode: {
    cursor: 'pointer',
  },
  highlightedNode: {
    outline: `4px solid ${alpha(Colors.SECONDARY_MAIN, 0.2)}`,
  },
  nodeContents: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    height: `${NODE_HEIGHT}px`,
    width: '100%',
  },
  nodeBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  nodeName: {
    overflow: 'hidden',
    maxWidth: '175px',
  },
  nodeIcons: {
    display: 'grid',
    gridTemplateRows: '[upper-node-icon] 1fr [lower-node-icon] 1fr',
    gridTemplateColumns: '32px',
    height: '100%',
    justifyItems: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  nodeNameSingleLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nodeNameMultiLine: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  checkbox: {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
  },
  handle: {
    display: 'none',
  },
  multiSelectIcon: {
    backgroundColor: theme.palette.success.main,
    color: Colors.WHITE,
    fontSize: '11px',
    fontWeight: 700,
    height: '14px',
    width: '14px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  entitiNodeMultiSelectIcon: {
    gridRow: 'lower-node-icon',
  },
  versionMultiSelectIcon: {
    gridColumn: 'right-node-icon',
    justifySelf: 'center',
  },
  warningText: {
    backgroundColor: theme.palette.warning.main,
  },
  collapseButton: {
    color: theme.palette.text.primary,
  },
}));
