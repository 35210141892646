import React, { ReactNode, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateMapGrid from 'client/app/apps/simulation-details/instructions/PlateMapGrid';
import { generateSimple9wellTemplate } from 'common/lib/defaultPlate';
import { PlateType } from 'common/types/plateType';
import { LayerPlate, Well } from 'common/types/steps';
import Colors from 'common/ui/Colors';
import { PRINT_HEIGHT_A4, PRINT_WIDTH_A4 } from 'common/ui/components/Print';
import { PlateIcon } from 'common/ui/icons/Plate';

function getDefaultPlate() {
  return {
    ...generateSimple9wellTemplate(),
    id: 'Default Plate',
    rows: 8,
    columns: 12,
    name: 'Unknown Plate',
  };
}

export default function PlateMap({
  name,
  content,
  plate = getDefaultPlate(),
  showConcentration,
  className,
  gridDecorators,
  showLocalSources,
  isPrint,
}: {
  name: string;
  content: LayerPlate;
  plate: PlateType;
  showConcentration: boolean;
  className?: string;
  gridDecorators?: ReactNode;
  showLocalSources: boolean;
  isPrint: boolean;
}) {
  const showSource = useMemo(() => {
    return showLocalSources
      ? (well: Well) => {
          return well.source?.plateName === name;
        }
      : undefined;
  }, [name, showLocalSources]);

  const [printScaleFactor, setPrintScaleFactor] = useState(1);

  return (
    <PlateMapWrapper className={className}>
      <PlateName>
        <PlateIcon />
        <Typography variant="body1">
          {name} ({plate.name})
        </Typography>
      </PlateName>
      <TableWrapper isPrint={isPrint}>
        <PlateMapGrid
          ref={(grid: HTMLDivElement) => {
            if (grid && isPrint && printScaleFactor === 1) {
              const gridContentRect = grid.getBoundingClientRect();
              const scaleX = PRINT_WIDTH_A4 / gridContentRect.width;
              const scaleY = PRINT_HEIGHT_A4 / gridContentRect.height;

              if (scaleX < 1 || scaleY < 1) {
                setPrintScaleFactor(Math.min(scaleX, scaleY));
              }
            }
          }}
          content={content}
          plate={plate}
          zoom={printScaleFactor}
          getWellState={well => well.added ?? well.final}
          getContextValue={
            showConcentration ? well => well.final.concentration : undefined
          }
          showSource={showSource}
          decorators={gridDecorators}
        />
      </TableWrapper>
    </PlateMapWrapper>
  );
}

const PlateMapWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, max-content)',
  gridTemplateRows: 'auto 1fr',

  '@media print': {
    pageBreakAfter: 'always',
    pageBreakInside: 'avoid',
  },
});

const TableWrapper = styled('div')<{ isPrint: boolean }>(({ isPrint }) => ({
  position: 'relative',
  borderTop: `1px solid ${Colors.GREY_30}`,
  borderLeft: `1px solid ${Colors.GREY_30}`,

  overflow: 'auto',
  width: isPrint ? 'fit-content' : '100%', // this ensures tables are scrollable before print
}));

const PlateName = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(3),
  background: Colors.GREY_20,
  justifySelf: 'start',
  padding: spacing(3, 5, 3, 4),
  borderRadius: spacing(2, 2, 0, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderBottomWidth: 0,
}));
