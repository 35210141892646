import React, { useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import partition from 'lodash/partition';
import { useQueryParam } from 'use-query-params';

import ExperimentsDialog from 'client/app/apps/experiments/ExperimentsDialog';
import {
  MUTATION_CREATE_EXPERIMENT,
  MUTATION_CREATE_EXPERIMENT_BLOCK,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENTS_INFO } from 'client/app/apps/experiments/gql/queries';
import { QUERY_FIND_WORK_TREES } from 'client/app/apps/work-tree/queries';
import { HIGHLIGHTED_ENTITY_PARAM } from 'client/app/apps/work-tree/WorkTree';
import ExperimentChip, {
  ExperimentChipSkeleton,
} from 'client/app/components/nav/experiment-chip-picker/ExperimentChip';
import {
  PopoverType,
  useExperimentChipPickerContext,
} from 'client/app/components/nav/experiment-chip-picker/ExperimentChipPickerContext';
import { NEW_EXPERIMENT_NAME } from 'client/app/components/NavigationSidepanel';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import {
  ArrayElement,
  CreateExperimentBlockMutationVariables,
  ExperimentBlockType as BlockType,
  ExperimentsInfoQuery,
  FindWorkTreesQueryVariables,
} from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workTreeRoutes } from 'client/app/lib/nav/actions';
import LabBenchBanner from 'common/assets/LabBenchBanner';
import { pluralize, pluralizeWord } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button, { Props } from 'common/ui/components/Button';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import { usePopover } from 'common/ui/hooks/usePopover';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { LightTheme } from 'common/ui/theme';
import { isNotNull } from 'common/utils';

export const EXPERIMENT_SOURCE_PARAM = 'experiment';

export type ExperimentChipPickerProps =
  | { workflowId: WorkflowId }
  | { simulationId: SimulationId }
  | { methodId: MethodId };

/**
 * Displays a picker component in the main nav bar. Used for allowing
 * addition of entities (i.e. worklfows, simulations) to an experiment.
 */
export default function ExperimentChipPicker(props: ExperimentChipPickerProps) {
  const classes = useStyles();

  const { popover, showPopover, hidePopover } = useExperimentChipPickerContext();

  const chipRef = useRef<HTMLDivElement>(null);

  const [isLoadingMutation, setIsLoadingMutation] = useState(false);

  const [createExperimentMutation] = useMutation(MUTATION_CREATE_EXPERIMENT);

  const [createBlockMutation] = useMutation(MUTATION_CREATE_EXPERIMENT_BLOCK);

  const [experimentsDialog, openExperimentsDialog] = useDialog(ExperimentsDialog);

  const queryVariables: FindWorkTreesQueryVariables = {
    workflowId: 'workflowId' in props ? props.workflowId : undefined,
    simulationId: 'simulationId' in props ? props.simulationId : undefined,
    methodId: 'methodId' in props ? props.methodId : undefined,
  };
  // It is not possible to add a method to an experiment.
  const allowAddingToExperiment = !('methodId' in props);

  const entity = getEntity(props);

  const {
    data,
    loading: queryWorkTreesLoading,
    error,
    refetch: refetchWorkTreesQuery,
  } = useQuery(QUERY_FIND_WORK_TREES, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  // We store the 'popover' locally to manage state here. This is because 'popover'
  // from context can be null (when MUI-Popover is closed), but the null value
  // means we don't render anything inside the MUI-Popover while it closes, and this
  // breaks the close animation for the MUI-Popover. Storing this state allows us to
  // to keep a non-null value, and then update as required in the useEffect if 'popover'
  // from context changes.
  const [popoverToRender, setPopoverToRender] = useState(popover);
  useEffect(() => {
    if (popover) {
      setPopoverToRender(popover);
    }
  }, [popover]);

  if (error) {
    return null;
  }

  const handleAddToExperiment = async (experimentId: ExperimentId) => {
    setIsLoadingMutation(true);
    hidePopover();
    const isWorkflow = 'workflowId' in props;
    const type = isWorkflow ? BlockType.WORKFLOW : BlockType.SIMULATION;

    const mutationVariables: CreateExperimentBlockMutationVariables = {
      experimentID: experimentId,
      type: type,
      workflowID: queryVariables.workflowId,
      simulationID: queryVariables.simulationId,
    };
    await createBlockMutation({
      variables: mutationVariables,
    });
    await refetchWorkTreesQuery();
    setIsLoadingMutation(false);
  };

  const handleCreateExperimentAndAddBlock = async (experimentName: string) => {
    setIsLoadingMutation(true);
    hidePopover();
    const { data } = await createExperimentMutation({
      variables: { name: experimentName },
    });
    if (!data?.createExperiment) {
      // TODO - Add some error handling here
      return;
    }
    await handleAddToExperiment(data.createExperiment.id);
  };

  const onAddToExperiment = async (unselectableExperiments?: ExperimentId[]) => {
    const experimentsDialogSelection = await openExperimentsDialog({
      disabledExperimentIds: unselectableExperiments,
    });
    if (experimentsDialogSelection === CANCEL_CHOICE) {
      return;
    }
    void handleAddToExperiment(experimentsDialogSelection);
  };

  const parentExperiments: ExperimentId[] = data?.findWorkTrees.filter(isNotNull) ?? [];
  const existsInExperiment = parentExperiments.length > 0;

  if (!existsInExperiment && !allowAddingToExperiment) {
    return null;
  }

  const label =
    queryWorkTreesLoading || isLoadingMutation ? (
      <Skeleton
        className={cx(classes.labelSkeleton, {
          [classes.labelSkeletonWithAvatar]:
            (queryWorkTreesLoading || isLoadingMutation) && existsInExperiment,
        })}
      />
    ) : existsInExperiment ? (
      'Experiments'
    ) : (
      'Add to experiment'
    );

  return (
    <LightTheme>
      <Tooltip
        title={
          existsInExperiment
            ? `This ${entity.type} is added to ${pluralize(
                parentExperiments.length,
                'experiment',
              )}`
            : `To see this ${entity.type} in the experiment map`
        }
      >
        <Chip
          ref={chipRef}
          className={classes.chip}
          classes={{
            label: cx({ [classes.labelLeftAlign]: existsInExperiment }),
          }}
          clickable
          onClick={() => showPopover('standard')}
          label={label}
          icon={<NewExperimentsIcon />}
          size="small"
          variant="outlined"
          deleteIcon={
            existsInExperiment ? (
              isLoadingMutation ? (
                <Skeleton variant="circular" className={classes.avatar} />
              ) : (
                <Avatar className={classes.avatar}>
                  <Typography variant="caption" color="inherit">
                    {parentExperiments.length < 100 ? parentExperiments.length : '99'}
                  </Typography>
                </Avatar>
              )
            ) : undefined
          }
          // deleteIcon is shown to the right of the chip, only when onDelete is defined.
          // We hijack this to allow us to render our custom component to the right of the chip.
          onDelete={existsInExperiment ? () => {} : undefined}
        />
      </Tooltip>
      <Popover
        classes={{ paper: classes.popover }}
        elevation={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={chipRef.current}
        open={!!popover}
        onClose={hidePopover}
      >
        {popoverToRender === 'standard' && (
          <ExperimentChipPickerList
            parentExperiments={parentExperiments}
            onAddToExperiment={onAddToExperiment}
            onCreateNewExperiment={handleCreateExperimentAndAddBlock}
            allowAddingToExperiment={allowAddingToExperiment}
            entity={entity}
          />
        )}
        {popoverToRender === 'structured-data' || popoverToRender === 'DOE' ? (
          <ExperimentPickerDOE
            onAddToExperiment={onAddToExperiment}
            onCreateNewExperiment={handleCreateExperimentAndAddBlock}
            popoverType={popoverToRender}
          />
        ) : null}
      </Popover>
      {experimentsDialog}
    </LightTheme>
  );
}

type ExperimentChipPickerListProps = {
  parentExperiments?: ExperimentId[];
  onAddToExperiment: (unselectableExperiments?: ExperimentId[]) => Promise<void>;
  onCreateNewExperiment: (experimentName: string) => void;
  allowAddingToExperiment: boolean;
  entity: Entity;
};

/**
 * Displays a list of experiment names.
 * If parentExperiments specified, will show info on the first 5 of those.
 * If parentExperiments not specified, will show info on the first 5 of the current users
 * experiments, ordered by lastModified.
 */
function ExperimentChipPickerList(props: ExperimentChipPickerListProps) {
  const {
    parentExperiments,
    onAddToExperiment,
    onCreateNewExperiment,
    allowAddingToExperiment,
    entity,
  } = props;
  const classes = useStyles();
  const currentUser = useUserProfile();

  const { getAbsoluteURL } = useNavigation();

  const [sourceExperimentId] = useQueryParam(EXPERIMENT_SOURCE_PARAM);

  const isInExperiments = parentExperiments && parentExperiments.length > 0;

  const { data, loading, error } = useQuery(QUERY_EXPERIMENTS_INFO, {
    variables: { experimentIds: parentExperiments },
    skip: !isInExperiments,
  });

  if (error) {
    return null;
  }

  const allExperiments = data?.experiments.items ?? [];
  const userHasNoDefinedExperiments = !isInExperiments && allExperiments.length === 0;
  // Only disable experiments in the dialog if the user has parentExperiments
  // otherwise, all of them should be enabled to allow selecting the first experiment.
  const disabledExperiments = isInExperiments
    ? allExperiments.map(experiment => experiment.id)
    : [];

  const [usersExperiments, otherUsersExperiments] = partition(
    allExperiments,
    exp => exp.createdBy.id === currentUser?.id,
  );

  const experimentsToShow = [...usersExperiments, ...otherUsersExperiments];

  type Experiment = ArrayElement<ExperimentsInfoQuery['experiments']['items']>;

  function renderExperimentChip(experiment: Experiment) {
    return (
      <ExperimentChip
        key={experiment.id}
        name={experiment.name}
        firstName={experiment.createdBy.firstName}
        lastName={experiment.createdBy.lastName}
        isAuthoredByCurrentUser={experiment.createdBy.id === currentUser?.id}
        showFocusIndicator={experiment.id === sourceExperimentId}
        href={
          getAbsoluteURL(workTreeRoutes.workTreeExperiment, {
            experimentId: experiment.id,
          }) + `?${HIGHLIGHTED_ENTITY_PARAM}=${entity.id}`
        }
      />
    );
  }

  return (
    <div className={classes.popoverContent}>
      <Typography variant="caption">
        {isInExperiments
          ? `This ${entity.type} is in the ${pluralizeWord(
              parentExperiments.length,
              'experiment',
            )} below`
          : `This ${entity.type} hasn't been added to any experiments yet`}
      </Typography>
      {loading && (
        <>
          <Skeleton className={cx(classes.labelSkeleton)} />
          <div className={classes.experimentsList}>
            {[...Array(5)].map((_, i) => (
              <ExperimentChipSkeleton key={i} />
            ))}
          </div>
        </>
      )}
      {!isInExperiments && <LabBenchBanner className={classes.labBenchBanner} />}
      {experimentsToShow.length > 0 && (
        <div className={classes.experimentsList}>
          {experimentsToShow.map(renderExperimentChip)}
        </div>
      )}
      {allowAddingToExperiment && (
        <div className={classes.actionButtons}>
          <Button
            className={cx(classes.button, classes.addButton, {
              [classes.buttonMargin]: !userHasNoDefinedExperiments,
            })}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => onAddToExperiment(disabledExperiments)}
            variant="secondary"
            size="small"
            startIcon={<AddIcon />}
          >
            <Typography variant="button">
              {isInExperiments ? 'Another existing experiment' : 'An existing experiment'}
            </Typography>
          </Button>
          <Divider className={classes.divider} />
          <CreateNewExperimentButton
            onCreateNewExperiment={onCreateNewExperiment}
            variant="tertiary"
            size="small"
          />
        </div>
      )}
    </div>
  );
}

type ExperimentPickerDOEProps = {
  onAddToExperiment: () => void;
  onCreateNewExperiment: (experimentName: string) => Promise<void>;
  popoverType: PopoverType;
};

function ExperimentPickerDOE({
  onAddToExperiment,
  onCreateNewExperiment,
  popoverType,
}: ExperimentPickerDOEProps) {
  const classes = useStyles();

  const copy =
    popoverType === 'structured-data'
      ? 'In order to structure data, the execution must be added to an experiment.'
      : 'In order to create a DOE design, the workflow must be added to an experiment.';

  return (
    <div className={cx(classes.popoverContent, classes.doePopover)}>
      <Typography variant="body1">{copy}</Typography>
      <Button variant="secondary" color="primary" onClick={() => onAddToExperiment()}>
        Add to an existing experiment
      </Button>
      <CreateNewExperimentButton
        className={classes.createNewButton}
        onCreateNewExperiment={onCreateNewExperiment}
        variant="secondary"
        color="primary"
      />
    </div>
  );
}

type CreateNewExperimentButtonProps = {
  onCreateNewExperiment: (experimentName: string) => void;
} & Props;

/**
 * Button that opens a popover with a textfield allowing the user to input a name
 * for the new experiment.
 * The onCreateNewExperiment is run on confirming the input.
 */
export function CreateNewExperimentButton({
  onCreateNewExperiment,
  ...props
}: CreateNewExperimentButtonProps) {
  const classes = useStyles();
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const [newName, setNewName] = useState(NEW_EXPERIMENT_NAME);

  const isNewNameValid = !!newName;

  const handleNameChange = useTextFieldChange(setNewName);

  const handleConfirm = () => {
    if (!isNewNameValid) {
      return;
    }
    onHidePopover();
    onCreateNewExperiment(newName);
  };

  const handleEnterKeyPress = useEnterKeyPress(handleConfirm);

  const handleShowPopover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setNewName(NEW_EXPERIMENT_NAME);
    onShowPopover(event);
  };

  return (
    <>
      <Button
        className={cx(
          classes.button,
          classes.createButton,
          classes.buttonOutline,
          classes.buttonMargin,
        )}
        onClick={handleShowPopover}
        {...props}
      >
        <Typography variant="button">Add to a new experiment</Typography>
      </Button>
      <Popover
        classes={{ paper: classes.popover }}
        elevation={4}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
      >
        <div className={cx(classes.popoverContent, classes.createNewExperimentPopover)}>
          <TextField
            variant="standard"
            className={classes.createNewExperimentTextField}
            autoFocus
            size="small"
            value={newName}
            onChange={handleNameChange}
            onFocus={e => e.target.select()}
            onKeyDown={handleEnterKeyPress}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.createNewExperimentInput },
            }}
          />
          <div className={classes.createNewExperimentConfirm}>
            <Button
              className={cx(classes.button, classes.buttonOutline)}
              variant="tertiary"
              size="small"
              disabled={!isNewNameValid}
              onClick={handleConfirm}
            >
              <Typography variant="button">Add</Typography>
            </Button>
            {newName && <Typography variant="body2">{`"${newName}"`}</Typography>}
          </div>
        </div>
      </Popover>
    </>
  );
}

type Entity = {
  type: string;
  id: WorkflowId | SimulationId | MethodId;
};

function getEntity(props: ExperimentChipPickerProps): Entity {
  if ('workflowId' in props) {
    return { type: 'workflow', id: props.workflowId };
  }
  if ('simulationId' in props) {
    return { type: 'simulation', id: props.simulationId };
  }
  return { type: 'method', id: props.methodId };
}

const useStyles = makeStylesHook(theme => ({
  chip: {
    color: theme.palette.text.primary,
    boxShadow: `0 0 0 1px ${theme.palette.text.primary}`,
    transition: 'box-shadow 0ms',
    padding: theme.spacing(2),
    width: '150px',
    justifyContent: 'space-between',
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.contrastText}`,
    },
    '& .MuiChip-icon': {
      color: theme.palette.text.primary,
    },
    '& .MuiChip-deleteIcon': {
      color: Colors.TEXT_PRIMARY,
      '&:hover': {
        color: Colors.TEXT_PRIMARY,
      },
    },
  },
  labelLeftAlign: {
    marginRight: 'auto',
  },
  avatar: {
    width: '16px',
    height: '16px',
    backgroundColor: theme.palette.text.primary,
  },
  labelSkeleton: {
    width: '110px',
    height: '18px',
    backgroundColor: theme.palette.text.primary,
  },
  labelSkeletonWithAvatar: {
    width: '90px',
  },
  popover: {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(3),
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 4),
    width: '280px',
  },
  experimentsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  button: {
    height: '24px',
    width: 'max-content',
  },
  buttonOutline: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  createButton: {
    color: theme.palette.primary.main,
  },
  addButton: {
    border: 'none',
  },
  startIcon: {
    marginRight: theme.spacing(2),
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  createNewExperimentTextField: {
    height: '24px',
  },
  createNewExperimentPopover: {
    gap: theme.spacing(5),
  },
  createNewExperimentInput: {
    backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
    padding: theme.spacing(2),
  },
  createNewExperimentConfirm: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  divider: {
    color: theme.palette.divider,
    marginTop: theme.spacing(5),
  },
  experimentCaption: {
    marginTop: theme.spacing(5),
  },
  labBenchBanner: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    margin: theme.spacing(3, 0),
  },
  createNewButton: {
    maxWidth: 'none',
    width: 'auto',
    margin: 0,
  },
  doePopover: {
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    width: '295px',
  },
}));
